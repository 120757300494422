import React from 'react';
import { useParams, Link } from 'react-router-dom';
import b1 from './images/b1.jpg'
import b2 from './images/b2.jpg'
import b3 from './images/b3.jpg'

import r1 from './images/r1.jpg'
import r2 from './images/r2.jpg'
import r3 from './images/r3.jpg'

import ed1 from './images/ed1.jpg'
import ed2 from './images/ed2.jpg'
import ed3 from './images/ed3.jpg'

import e1 from './images/e1.jpg'
import e2 from './images/e2.jpg'
import e3 from './images/e3.jpg'

import mf1 from './images/mf1.jpg'
import mf2 from './images/mf2.jpg'
import md5 from './images/md5.jpg'

import md1 from './images/md1.jpg'
import md2 from './images/md2.jpg'
import md3 from './images/md3.jpg'

import lo1 from './images/lo1.jpg'
import lo2 from './images/lo2.jpg'
import lo3 from './images/lo3.jpg'

import mdi1 from './images/mdi1.jpg'
import mdi2 from './images/mdi2.jpg'
import mdi3 from './images/mdi3.jpg'

import ins1 from './images/ins1.jpg'
import ins2 from './images/ins2.jpg'
import ins3 from './images/ins3.jpg'

import pbs1 from './images/pbs1.jpg'
import pbs2 from './images/ins2.jpg'
import pbs3 from './images/pbs3.jpg'

import lss1 from './images/lss1.png'
import lss2 from './images/lss2.jpg'
import lss3 from './images/lss3.jpg'

import dm1 from './images/128511.jpg'
import dm2 from './images/6511466.gif'
import dm3 from './images/456498.jpg'

import './IndustryPage.css'
import CustomIndustrySlider from './CustomIndustrySlider/CustomIndustrySlider';
import SocialMedia from '../Home/SocialMedia';

const IndustryPage = () => {
  const { name } = useParams();

  const industryDetails = {
    'Digital Banking': {
      description1: `Digital banking is transforming the financial sector by: 
      - Offering 24/7 access to banking services through mobile applications, enabling customers to perform transactions at their convenience.
      - Utilizing advanced security measures, such as biometric authentication and two-factor authentication, to enhance customer trust and protect sensitive data.
      - Leveraging big data analytics to provide personalized banking experiences and targeted financial products based on customer behavior and preferences.
      - Implementing chatbots and AI-driven customer service solutions to provide instant assistance and support, improving overall customer satisfaction.
      - Integrating digital wallets and contactless payment options, allowing for seamless and efficient transactions in a cashless society.
      - Enhancing financial literacy through online resources and tools, helping customers make informed financial decisions.`,
  
      description2: `The impact of AI in digital banking is expanding through: 
      - Utilizing machine learning algorithms for fraud detection and prevention, analyzing transaction patterns to identify suspicious activities in real-time.
      - Implementing predictive analytics to forecast customer behavior, enabling banks to offer timely and relevant financial advice and services.
      - Developing AI-powered robo-advisors that provide automated investment advice and portfolio management, making investing accessible to everyone.
      - Using natural language processing to improve customer interaction and understanding through voice-activated banking assistants.
      - Streamlining compliance processes with AI-driven solutions that monitor regulations and ensure adherence, reducing the risk of penalties.
      - Enhancing risk management through data-driven insights, allowing banks to better understand and mitigate potential threats.`,
  
      description3: `The future of digital banking points to:
      - The integration of blockchain technology for secure, transparent transactions and smart contracts, reducing the need for intermediaries.
      - Implementing digital identity verification solutions to enhance customer onboarding processes while ensuring security and privacy.
      - Developing augmented reality (AR) applications for immersive banking experiences, allowing customers to visualize financial data and investments.
      - Exploring quantum computing for advanced data analysis and faster processing, potentially revolutionizing transaction speeds and security.
      - Creating community-focused banking platforms that promote local businesses and support sustainable financial practices.
      - Utilizing virtual reality (VR) to simulate financial education scenarios, helping users understand complex financial products in an engaging way.`,
  
  images: {
    descriptionImage1: b1,
    descriptionImage2: b2,
    descriptionImage3: b3,
  }
},

    'Smart Retail': {
    description1: `Smart retail is revolutionizing the shopping experience by:
    - Implementing IoT devices to track inventory levels in real-time, ensuring that popular products are always available for customers.
    - Using facial recognition technology to personalize customer experiences by analyzing shopper preferences and behaviors.
    - Leveraging data analytics to understand shopping trends and optimize store layouts, improving product placement and sales.
    - Offering mobile payment solutions that streamline the checkout process, reducing wait times and enhancing customer satisfaction.
    - Integrating augmented reality (AR) for virtual try-ons, allowing customers to visualize how products will look before purchasing.
    - Creating omnichannel experiences that seamlessly connect online and in-store shopping, enabling customers to shop anywhere, anytime.`,

    description2: `The role of AI in smart retail is evolving through:
    - Utilizing machine learning algorithms for demand forecasting, enabling retailers to adjust stock levels based on anticipated customer needs.
    - Implementing AI-driven chatbots for customer service, providing instant responses to queries and improving engagement.
    - Analyzing shopping behavior through AI to create personalized marketing campaigns that increase conversion rates.
    - Developing automated checkout systems that utilize computer vision to identify products, eliminating the need for manual scanning.
    - Enhancing customer loyalty programs with AI, analyzing data to tailor rewards and incentives to individual shopping habits.
    - Using voice recognition technology for a hands-free shopping experience, enabling customers to search for products and make purchases via voice commands.`,

    description3: `The future of smart retail points to:
    - The adoption of blockchain for secure and transparent supply chain management, ensuring authenticity and traceability of products.
    - Creating smart mirrors that provide personalized product recommendations based on customer preferences and previous purchases.
    - Implementing virtual reality (VR) showrooms that allow customers to explore products in a 3D environment, enhancing the online shopping experience.
    - Integrating emotion AI to gauge customer reactions and adjust in-store experiences accordingly, improving customer satisfaction.
    - Exploring social commerce, where retailers leverage social media platforms for direct sales and customer engagement.
    - Developing eco-friendly retail solutions that promote sustainability and environmentally conscious shopping habits.`,

  images: {
    descriptionImage1: r1,
    descriptionImage2: r2,
    descriptionImage3: r3,
  }
},

    'Healthcare Innovation': {
    description1: `Healthcare innovation is enhancing patient care through:
    - Implementing telemedicine platforms that enable remote consultations, improving access to healthcare for patients in rural areas.
    - Utilizing wearable health devices that monitor vital signs and send real-time data to healthcare providers for proactive management of health conditions.
    - Leveraging big data analytics to identify health trends and improve decision-making for patient treatment plans.
    - Integrating electronic health records (EHR) systems that streamline patient data management and enhance communication among healthcare professionals.
    - Utilizing AI for predictive analytics, helping to identify potential health risks and enabling early intervention.
    - Offering mobile health applications that empower patients to manage their health and wellness effectively.`,

    description2: `The role of AI in healthcare innovation is advancing through:
    - Utilizing AI algorithms for diagnostic imaging, improving accuracy and speed in identifying medical conditions.
    - Implementing virtual health assistants that provide personalized health advice and medication reminders, improving patient adherence to treatment plans.
    - Using machine learning to analyze patient data for personalized medicine, tailoring treatments based on individual genetic profiles.
    - Enhancing clinical decision support systems with AI to provide real-time recommendations based on the latest medical research and patient data.
    - Developing chatbots for patient triage, allowing for efficient assessment of symptoms and directing patients to appropriate care.
    - Employing robotic surgery systems that enhance precision and reduce recovery times for patients.`,

    description3: `The future of healthcare innovation points to:
    - The integration of blockchain technology for secure patient data sharing, enhancing privacy and security in healthcare transactions.
    - Exploring gene editing technologies that allow for personalized treatments and potential cures for genetic disorders.
    - Implementing augmented reality (AR) for surgical training, providing immersive learning experiences for medical professionals.
    - Utilizing 3D printing for creating custom medical devices and implants tailored to individual patient needs.
    - Developing AI-driven platforms for mental health support, offering personalized therapy sessions and resources.
    - Advancing global health initiatives through technology, improving access to care and resources in underserved populations.`,

  images: {
    descriptionImage1: md1,
    descriptionImage2: md2,
    descriptionImage3: md3,
  }
},

'Next-Gen Manufacturing': {
  description1: `Next-gen manufacturing is transforming the industry through:
  - Utilizing IoT devices to monitor equipment performance in real-time, enhancing maintenance and reducing downtime.
  - Implementing robotics and automation to streamline production processes, increasing efficiency and reducing human error.
  - Leveraging advanced analytics to optimize supply chain management and inventory control, reducing costs and waste.
  - Incorporating additive manufacturing (3D printing) for rapid prototyping and custom part production, enhancing product development cycles.
  - Utilizing virtual reality (VR) for immersive training simulations, improving workforce skills and safety in manufacturing environments.
  - Enhancing product quality through machine learning algorithms that analyze production data for continuous improvement.`,

  description2: `The role of AI in next-gen manufacturing is evolving through:
  - Implementing predictive maintenance solutions that use AI to forecast equipment failures, minimizing unplanned outages.
  - Utilizing AI-driven quality control systems that analyze production output in real-time, ensuring adherence to quality standards.
  - Enhancing supply chain transparency with AI algorithms that track materials from origin to production, optimizing logistics.
  - Developing AI-powered design tools that assist engineers in creating innovative product designs based on consumer insights.
  - Automating inventory management processes with AI, enabling just-in-time delivery and reducing holding costs.
  - Using AI for workforce management, optimizing labor allocation based on production needs and employee skills.`,

  description3: `The future of next-gen manufacturing points to:
  - The integration of blockchain technology for secure and transparent supply chain tracking, enhancing accountability.
  - Exploring nanotechnology for creating advanced materials with enhanced properties and functionalities.
  - Implementing digital twins for real-time simulations of production processes, enabling proactive management and optimization.
  - Utilizing augmented reality (AR) for remote collaboration and troubleshooting, enhancing communication between teams.
  - Developing smart factories that utilize AI and IoT for fully automated production lines, improving efficiency and reducing costs.
  - Advancing sustainability initiatives in manufacturing by utilizing renewable energy sources and eco-friendly materials.`,

  images: {
    descriptionImage1: mf1,
    descriptionImage2: mf2,
    descriptionImage3: md5,
  }
},

'Capital Market Solutions': {
  description1: `Capital market solutions are evolving through:
  - Leveraging big data analytics to provide insights into market trends, helping investors make informed decisions.
  - Utilizing algorithmic trading systems that execute trades based on predefined criteria, improving efficiency and reducing costs.
  - Implementing blockchain technology for secure and transparent transactions, enhancing trust in capital markets.
  - Offering robo-advisory services that provide automated investment advice, making investing accessible to a wider audience.
  - Integrating artificial intelligence to analyze vast datasets for better risk management and investment strategies.
  - Providing mobile trading applications that allow investors to trade and monitor their portfolios in real-time.`,

  description2: `The role of AI in capital market solutions is expanding through:
  - Utilizing machine learning algorithms to identify trading patterns and enhance predictive analytics for market forecasting.
  - Implementing natural language processing (NLP) to analyze news and social media sentiment, informing trading strategies.
  - Developing AI-driven risk assessment tools that evaluate investment risks based on historical data and market conditions.
  - Enhancing customer experience with personalized investment recommendations based on individual financial goals.
  - Automating compliance processes with AI to ensure adherence to regulations and reduce operational risks.
  - Using AI for fraud detection in trading activities, enhancing security and trust in capital markets.`,

  description3: `The future of capital market solutions points to:
  - The integration of quantum computing for faster data processing and analysis, revolutionizing investment strategies.
  - Exploring decentralized finance (DeFi) solutions that offer alternative financing options and disrupt traditional capital markets.
  - Implementing smart contracts to automate trading processes and ensure compliance with contractual obligations.
  - Utilizing sentiment analysis tools to gauge market reactions and inform investment decisions.
  - Developing collaborative platforms that connect investors, traders, and institutions for improved market efficiency.
  - Advancing sustainability-focused investment strategies that prioritize environmental, social, and governance (ESG) factors.`,

  images: {
    descriptionImage1: dm1,
    descriptionImage2: dm2,
    descriptionImage3: dm3,
  }
},


'Tech-Enabled Education': {

  description1: `Technology in education is transforming traditional learning by: 
  - Enabling blended learning models that combine in-person instruction with online platforms, allowing students to learn at their own pace while benefiting from direct teacher interaction.
  - Using cloud-based tools like Google Classroom, Microsoft Teams, and Zoom to facilitate seamless communication and collaboration between students and teachers, ensuring that learning can continue regardless of physical location.
  - Offering mobile learning applications that allow students to access educational content anytime, anywhere, making learning more flexible and accessible to people in remote or underserved areas.
  - Promoting self-paced learning, where students can revisit difficult topics and move faster through easier material, creating a more personalized educational experience.
 - Leveraging online assessments and automated grading systems to offer faster feedback, reducing teacher workloads and allowing students to receive instant evaluations of their performance.
  - Empowering teachers with data-driven insights into student progress, enabling more informed decisions about instructional methods and interventions for students who may need additional support.`,

  description2: `The role of AI and machine learning in education is expanding through: 
  - Implementing adaptive learning systems that personalize educational content to fit each student's learning speed, strengths, and weaknesses, ensuring that students receive the most effective instruction possible.
  - Using AI-driven tutoring systems to provide students with personalized guidance, offering instant feedback and tailored support to help them master complex subjects.
  - Employing automated grading systems that reduce teacher workload by instantly assessing student assignments, quizzes, and tests, allowing for more time to focus on interactive teaching.
  - Utilizing AI-based content creation tools that generate personalized quizzes, study materials, and even lesson plans based on student performance data, ensuring that learning resources are always aligned with student needs.
  - Integrating data analytics to track student performance in real-time, identifying patterns in learning behaviors and providing teachers with actionable insights to improve classroom outcomes.
 - Developing AI-based predictive tools that forecast future academic performance and potential challenges, allowing educators to proactively intervene before students fall behind.
  - Encouraging gamification in education through AI, where students engage in interactive learning games that adapt based on their progress, making education more enjoyable and effective.`,

  description3: `Description 3: The future of education technology points to:
  - The use of virtual reality (VR) to create immersive learning environments where students can explore historical events, perform science experiments, or travel to different parts of the world, all without leaving their classroom.
  - Leveraging augmented reality (AR) to bring textbooks to life by overlaying digital images, videos, and interactive elements onto physical pages, creating a more engaging and dynamic learning experience.
  - Developing AI-driven career guidance platforms that use student data to recommend career paths, universities, and skill-building opportunities, providing personalized roadmaps based on individual interests and aptitudes.
  - Implementing blockchain technology for secure academic records and credentialing, ensuring that diplomas, certificates, and transcripts can be stored safely and accessed instantly by employers or educational institutions.
  - Creating collaborative global classrooms where students from different countries can work together on projects, sharing ideas and perspectives, thereby fostering a more global understanding of subjects.
  
 - Integrating emotion AI to analyze student engagement and emotional responses during lessons, providing teachers with insights on how to adjust content delivery to maintain interest and motivation.
  - Exploring quantum computing in the future to process vast amounts of educational data, potentially enabling breakthroughs in how we understand and enhance learning processes for all students.`, 

  images: {
    descriptionImage1: ed1,
    descriptionImage2: ed2,
    descriptionImage3: ed3,
  }
},

'Energy & Sustainability': {
    description1: `Energy and sustainability initiatives are driving change through:
    - Implementing renewable energy sources such as solar, wind, and hydroelectric power to reduce carbon emissions.
    - Utilizing smart grids that optimize energy distribution and improve efficiency in power consumption.
    - Promoting energy-efficient technologies and practices in buildings, transportation, and industrial processes.
    - Leveraging data analytics to monitor energy usage and identify opportunities for reduction and optimization.
    - Implementing electric vehicles and charging infrastructure to support the transition to sustainable transportation.
    - Encouraging corporate social responsibility (CSR) initiatives focused on environmental sustainability and community engagement.`,

    description2: `The role of AI in energy and sustainability is expanding through:
    - Utilizing machine learning algorithms to optimize energy consumption patterns in real-time, reducing waste.
    - Implementing predictive analytics to forecast energy demand and improve resource allocation.
    - Using AI to monitor and maintain renewable energy systems, enhancing their efficiency and reliability.
    - Developing smart home technologies that allow consumers to manage their energy use effectively and reduce costs.
    - Analyzing environmental data with AI to identify trends and inform policy decisions regarding sustainability.
    - Automating sustainability reporting processes with AI tools that streamline data collection and analysis.`,

    description3: `The future of energy and sustainability points to:
    - The integration of blockchain technology for transparent energy transactions and peer-to-peer energy trading.
    - Exploring carbon capture and storage solutions that reduce greenhouse gas emissions from industrial processes.
    - Implementing smart cities that utilize IoT and AI for efficient resource management and sustainable urban planning.
    - Advancing energy storage technologies that enhance the reliability and effectiveness of renewable energy sources.
    - Promoting circular economy initiatives that prioritize resource efficiency and waste reduction.
    - Developing global partnerships to address climate change and promote sustainable development practices.`,

  images: {
    descriptionImage1: e1,
    descriptionImage2: e2,
    descriptionImage3: e3,
  }
},

'Logistics & Supply Chain': {
    description1: `Logistics and supply chain innovations are enhancing efficiency through:
    - Utilizing IoT sensors to track shipments in real-time, improving visibility and reducing delays.
    - Implementing automated warehousing systems that streamline inventory management and reduce labor costs.
    - Leveraging data analytics to optimize routing and reduce transportation costs, enhancing overall supply chain efficiency.
    - Integrating blockchain technology for secure and transparent tracking of goods from source to destination.
    - Utilizing drones for last-mile delivery, improving speed and reducing transportation costs.
    - Developing collaborative platforms that enhance communication and coordination between supply chain partners.`,

    description2: `The role of AI in logistics and supply chain is advancing through:
    - Implementing machine learning algorithms for demand forecasting, enabling better inventory management and reducing stockouts.
    - Utilizing AI for route optimization, improving delivery times and reducing fuel consumption.
    - Analyzing shipping data with AI to identify inefficiencies and enhance operational performance.
    - Developing predictive maintenance solutions for logistics equipment, reducing downtime and repair costs.
    - Automating administrative processes with AI tools that streamline invoicing and shipment tracking.
    - Enhancing customer service through AI-driven chatbots that provide real-time updates on shipment status.`,

    description3: `The future of logistics and supply chain points to:
    - The integration of autonomous vehicles for transportation and delivery, reducing reliance on human drivers.
    - Exploring sustainable logistics practices that prioritize eco-friendly transportation methods and reduce carbon footprints.
    - Implementing advanced analytics for supply chain risk management, improving resilience against disruptions.
    - Utilizing robotics for inventory management and order fulfillment, enhancing efficiency and accuracy.
    - Developing collaborative supply chain networks that leverage shared resources for improved performance.
    - Advancing digital twin technology to simulate supply chain processes and optimize operations in real-time.`,

    images: {
      descriptionImage1: lo1,
      descriptionImage2: lo2,
      descriptionImage3: lo3,
    }
  },

  'Media & Entertainment': {
    description1: `Media and entertainment innovations are reshaping consumer experiences through:
    - Implementing streaming services that provide on-demand access to a vast library of content, revolutionizing viewing habits.
    - Leveraging data analytics to understand audience preferences and deliver personalized content recommendations.
    - Utilizing augmented reality (AR) and virtual reality (VR) technologies to create immersive experiences for users.
    - Incorporating interactive storytelling that engages audiences and encourages participation in content creation.
    - Developing cross-platform experiences that allow users to engage with content across multiple devices seamlessly.
    - Enhancing content creation with AI tools that assist in scriptwriting, video editing, and production processes.`,

    description2: `The role of AI in media and entertainment is evolving through:
    - Utilizing machine learning algorithms for content recommendation systems that personalize user experiences based on viewing habits.
    - Implementing AI-driven analytics to measure audience engagement and optimize marketing strategies for content.
    - Developing automated content generation tools that create unique stories, articles, and videos based on user preferences.
    - Enhancing post-production processes with AI tools that automate video editing and special effects, reducing production time.
    - Utilizing natural language processing for sentiment analysis, informing content creators about audience reactions and feedback.
    - Creating virtual influencers powered by AI, engaging audiences and promoting brands in innovative ways.`,

    description3: `The future of media and entertainment points to:
    - The integration of blockchain technology for secure and transparent content ownership and distribution.
    - Exploring interactive and immersive experiences that redefine storytelling and audience engagement.
    - Implementing 5G technology for faster streaming and enhanced user experiences in mobile entertainment.
    - Developing platforms that support user-generated content and promote community-driven creativity.
    - Advancing AI capabilities to analyze trends and predict future entertainment preferences for better content creation.
    - Fostering collaboration between technology and creative professionals to drive innovation in the industry.`,

    images: {
      descriptionImage1:mdi1,
      descriptionImage2:mdi2,
      descriptionImage3:mdi3,
    }
  },

  'Insurance Innovation': {
    description1: `Insurance innovation is reshaping the industry through:
    - Implementing digital platforms that streamline policy purchases and claims processes, enhancing customer experiences.
    - Utilizing data analytics to assess risk more accurately, leading to more personalized premium pricing.
    - Leveraging AI-driven chatbots for customer service, providing instant support and information to policyholders.
    - Developing telematics solutions for usage-based insurance, rewarding safe driving behaviors with discounts.
    - Incorporating blockchain technology for transparent claims processing and fraud prevention.
    - Enhancing underwriting processes with machine learning algorithms that analyze large datasets for better risk assessment.`,

    description2: `The role of AI in insurance innovation is expanding through:
    - Utilizing predictive analytics to identify potential claims fraud before it occurs, reducing losses for insurers.
    - Implementing AI-driven risk assessment tools that analyze individual behaviors and circumstances to tailor insurance products.
    - Enhancing customer interactions through AI chatbots that provide personalized advice and support.
    - Developing automated underwriting solutions that reduce processing times and improve efficiency.
    - Analyzing market trends and customer data with AI to inform product development and marketing strategies.
    - Creating personalized insurance offerings based on individual risk profiles and needs, enhancing customer satisfaction.`,

    description3: `The future of insurance innovation points to:
    - The integration of IoT devices for real-time monitoring and risk assessment, leading to more accurate pricing.
    - Exploring parametric insurance models that provide automatic payouts based on predefined conditions.
    - Implementing advanced analytics for claims management, improving accuracy and reducing processing times.
    - Developing platforms that facilitate peer-to-peer insurance models, fostering community support and shared risk.
    - Advancing digital literacy initiatives to enhance customer understanding of insurance products and services.
    - Promoting sustainability in insurance practices through responsible investment strategies and risk management.`,

    images: {
      descriptionImage1: ins1,
      descriptionImage2: ins2,
      descriptionImage3: ins3,
    }
  },

  'Life Sciences': {
    description1: `Life sciences innovations are driving advancements through:
    - Utilizing big data analytics to inform drug discovery and development processes, speeding up the time to market.
    - Implementing precision medicine that tailors treatment plans based on individual genetic profiles.
    - Leveraging AI for medical imaging analysis, improving diagnostic accuracy and efficiency.
    - Developing telehealth solutions that provide remote consultations and monitoring for patients.
    - Incorporating wearable technology that collects health data in real time, empowering patients to manage their health.
    - Enhancing clinical trials with AI-driven patient recruitment and data analysis, optimizing study outcomes.`,

    description2: `The role of AI in life sciences is advancing through:
    - Utilizing machine learning algorithms to identify patterns in large datasets, accelerating research and discovery.
    - Implementing AI tools for predictive modeling that inform patient treatment plans and outcomes.
    - Enhancing drug discovery with AI simulations that predict molecule interactions and potential side effects.
    - Developing virtual health assistants powered by AI to support patient engagement and adherence to treatment plans.
    - Analyzing clinical data with AI to improve trial designs and reduce costs associated with drug development.
    - Creating personalized health solutions based on real-time patient data and advanced analytics.`,

    description3: `The future of life sciences points to:
    - The integration of genomic data for personalized medicine that addresses individual health needs and risks.
    - Exploring advanced robotics for surgical procedures that enhance precision and minimize invasiveness.
    - Implementing digital health platforms that provide comprehensive health management and monitoring tools.
    - Advancing biotechnology for sustainable solutions in healthcare and environmental applications.
    - Promoting collaborations between healthcare providers and technology firms to drive innovation in patient care.
    - Enhancing data sharing initiatives that improve collaboration and research outcomes across the life sciences field.`,
    images: {
      descriptionImage1: lss1,
      descriptionImage2: lss2,
      descriptionImage3: lss3,
    }
  },

  'Public Services Transformation': {
    description1: `Public services transformation is enhancing government efficiency through:
    - Implementing e-governance solutions that streamline service delivery and improve accessibility for citizens.
    - Utilizing data analytics to inform policy decisions and allocate resources more effectively.
    - Developing digital platforms that facilitate citizen engagement and feedback in public service initiatives.
    - Leveraging AI for predictive analytics that inform public safety measures and resource allocation.
    - Enhancing transparency in government operations through open data initiatives and reporting.
    - Promoting cross-agency collaboration for improved service delivery and community support.`,

    description2: `The role of AI in public services transformation is growing through:
    - Utilizing machine learning algorithms to analyze citizen data and improve service delivery efficiency.
    - Implementing AI-driven chatbots for citizen inquiries, providing instant support and information.
    - Enhancing data security and privacy measures with AI tools that monitor and protect sensitive information.
    - Developing predictive models that anticipate public service demands and allocate resources accordingly.
    - Analyzing social media data with AI to gauge public sentiment and inform policy decisions.
    - Creating collaborative platforms for inter-agency cooperation and information sharing.`,

    description3: `The future of public services transformation points to:
    - The integration of blockchain technology for secure and transparent public records management.
    - Exploring smart city initiatives that utilize IoT and AI for efficient urban planning and management.
    - Implementing data-driven approaches to address social challenges and improve community services.
    - Developing citizen-centric platforms that prioritize user experience and accessibility.
    - Advancing partnerships between public and private sectors to enhance service delivery and innovation.
    - Promoting digital literacy initiatives that empower citizens to engage with government services effectively.`,

    images: {
      descriptionImage1: pbs1,
      descriptionImage2: pbs2,
      descriptionImage3: pbs3,
    }
  }


};

  const industry = industryDetails[name] || {
    description1: 'Details about this industry are currently unavailable.',
    description2: '',
    description3: '',
    images: {
      descriptionImage1: '/images/default.jpg',
      descriptionImage2: '/images/default.jpg',
      descriptionImage3: '/images/default.jpg',
    }
  };

  

  return (
    <div className='nenu'>
          <h2 className="custom-heading">{name}</h2>

    <div className="container mt-5 pt-5">

      {/* Description Section 1 */}
      <div className="industry-section mb-2">
        <p className="section-content mb-5 ">{industry.description1}</p>
        <div className="image-section">
          <img src={industry.images.descriptionImage1} alt={`${name} description 1`} className="img-fluid" style={{ width: '100%', objectFit: 'cover' }} />
        </div>
      </div>

      {/* Description Section 2 */}
      <div className="industry-section mb-2">
        <p className="section-content mb-5">{industry.description2}</p>
        <div className="image-section">
          <img src={industry.images.descriptionImage2} alt={`${name} description 2`} className="img-fluid" style={{ width: '100%', objectFit: 'cover' }} />
        </div>
      </div>

      {/* Description Section 3 */}
      <div className="industry-section mb-2">
        <p className="section-content mb-5">{industry.description3}</p>
        <div className="image-section">
          <img src={industry.images.descriptionImage3} alt={`${name} description 3`} className="img-fluid" style={{ width: '100%', objectFit: 'cover' }} />
        </div>
      </div>

      {/* Back to Industries Button */}
      <div className="text-center">
        <Link to="/industry/registor" className="btn btn-primary"> are you Ready to Handover the Project ?</Link>
      </div>
      <CustomIndustrySlider/>
    </div>
    <SocialMedia/>
    </div>
  );
};

export default IndustryPage;