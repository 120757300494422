import React from "react";

function OfficeMap() {
  return (
    <div style={{ width: "100%", height: "299px" }}>
      <iframe
        title="office-location"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 10 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1198.5392447215998!2d77.5782512!3d14.6744177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb14b809298fd65%3A0x5c40d8d139b25d1c!2sAHIRAA%20LEARNING%20PRIVATE%20LIMITED!5e3!3m2!1sen!2sin!4v1695787333214!5m2!1sen!2sin"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default OfficeMap;
