import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const ApplicationAnalyst = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Application Analyst</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>2-4 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-AA-192526</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Application Analyst</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Evaluate, develop, and manage software applications to ensure they meet business requirements and user needs.</li>
                    <li>Collaborate with stakeholders to gather application requirements and document functional specifications.</li>
                    <li>Conduct regular analysis and review of application performance, identifying areas for improvement.</li>
                    <li>Provide technical support and troubleshooting for application-related issues, ensuring minimal downtime.</li>
                    <li>Assist in the implementation and integration of new applications or upgrades, coordinating with various teams.</li>
                    <li>Develop and maintain documentation for application processes, procedures, and user guides.</li>
                    <li>Stay updated on industry trends and advancements in technology to recommend enhancements and upgrades.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Proficiency in application development methodologies and tools.</li>
                    <li>Experience with database management and query languages such as SQL.</li>
                    <li>Familiarity with software development lifecycle (SDLC) processes.</li>
                    <li>Knowledge of programming languages like Java, C#, or Python is a plus.</li>
                    <li>Understanding of system integration techniques and APIs.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong analytical and problem-solving skills to address application-related issues.</li>
                    <li>Excellent communication skills to interact with both technical and non-technical stakeholders.</li>
                    <li>Ability to work collaboratively in a team environment while also managing individual tasks effectively.</li>
                    <li>Strong organizational skills with the ability to manage multiple projects and deadlines.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Information Technology, or a related field.</p>
                <p>Relevant certifications in application analysis or software development are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default ApplicationAnalyst;
