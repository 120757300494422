import React from "react";

const DatabaseAdministrator = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Database Administrator</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-DBA-192518</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Database Administrator</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
               
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Manage, monitor, and maintain organizational databases to ensure data integrity, availability, and performance.</li>
                    <li>Perform routine database maintenance tasks, including backup, recovery, and security checks.</li>
                    <li>Collaborate with development and infrastructure teams to optimize and troubleshoot database performance.</li>
                    <li>Ensure database security by implementing appropriate access controls and encryption protocols.</li>
                    <li>Develop and document database standards, policies, and procedures.</li>
                    <li>Plan and execute database migrations, upgrades, and patch installations as needed.</li>
                    <li>Provide support for database-related queries, issues, and outages, ensuring minimal downtime.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: SQL, MySQL, Oracle, PostgreSQL.</li>
                    <li>Experience with database performance tuning, optimization, and indexing.</li>
                    <li>Familiarity with database backup, restore, and disaster recovery processes.</li>
                    <li>Knowledge of database security best practices.</li>
                    <li>Experience with cloud databases (e.g., AWS RDS, Azure SQL) is a plus.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong problem-solving skills with attention to detail.</li>
                    <li>Ability to work under pressure and meet deadlines in a fast-paced environment.</li>
                    <li>Excellent communication and teamwork skills to collaborate with cross-functional teams.</li>
                    <li>Ability to document and automate database tasks using scripts and tools.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Information Technology, or a related field.</p>
                <p>Relevant certifications (e.g., Oracle Certified Professional, Microsoft SQL Server Certification) are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default DatabaseAdministrator;
