import React, { useState, useRef } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Navbar123() {
  const [isActive, setIsActive] = useState(false);
  const toggleRef = useRef(null);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <Navbar expand="lg" className="Hnavbar">
      <div className="container d-flex align-items-center ">
        <div className="d-flex align-items-center">
          <img
            src="./LogoHead.png"
            alt="Brand Logo"
            style={{ borderRadius: '150px', height: '33px', width: '32px' }}
          />
          <Link className="navbar-brand" style={{ color: 'white', padding: '12px' }} to="/">
            Ahiraa Learning Pvt.Ltd
          </Link>
        </div>

        {/* Navbar Toggle Button with Active State */}
        <Navbar.Toggle
          ref={toggleRef}
          aria-controls="basic-navbar-nav"
          style={{ color: 'white', backgroundColor: 'white' }}
          onClick={handleToggle}
          className={isActive ? 'active' : ''}
        >
          <span className="navbar-toggler-icon" style={{ backgroundColor: 'white' }} />
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Link className="nav-link-custom" to="/">Home</Link>
            <Link className="nav-link-custom" to="/AboutUs">About</Link>
            <Link className="nav-link-custom" to="/Services">Service</Link>
            <Link className="nav-link-custom" to="/LMS">LMS</Link>
            <Link className="nav-link-custom" to="https://forms.gle/bc8W38kTRHxgT44W6" target="_blank" rel="noreferrer">
              IND Student Form
            </Link>
            <Link className="nav-link-custom" to="https://forms.gle/MuwuwTTHBMPJeTCE7" target="_blank" rel="noreferrer">
              International Student Form
            </Link>
            <Link className="nav-link-custom" to="/CareerPage">Careers</Link>
            <Link className="nav-link-custom" to="/IndustrySelection">Product & IT Services</Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Navbar123;
