import React, { useState } from 'react';
import './Chatbot.css'; // Import your custom CSS for the styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome icons

const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const getCurrentDate = () => {
    const now = new Date();
    return now.toLocaleDateString();
};

const getCurrentDay = () => {
    const now = new Date();
    return now.toLocaleDateString('en-US', { weekday: 'long' });
};

const prebuiltResponses = [
  {
      keywords: ['current time', 'what is the time', 'time now', 'time'],
      response: `The current time is ${getCurrentTime()}. Is there anything else I can assist you with?`
  },
  {
      keywords: ['current date', 'what is today\'s date', 'today\'s date', 'date'],
      response: `Today's date is ${getCurrentDate()}. Would you like to know more about our courses?`
  },
  {
      keywords: ['what day is it', 'day of the week', 'which day', 'day'],
      response: `Today is ${getCurrentDay()}. Feel free to ask me about our courses or any other queries!`
  },
  {
      keywords: ['what is java', 'what does java do', 'explain java', 'java'],
      response: 'Java is a popular programming language known for its versatility. At Ahiraa Learning, we offer a comprehensive Java course to help you master it!'
  },
  {
      keywords: ['what is python', 'python language', 'explain python', 'python'],
      response: 'Python is a high-level programming language used for web development, data science, AI, and more. Our Python course can take you from beginner to advanced!'
  },
  {
      keywords: ['what is ai', 'ai course', 'need ai', 'what does ai mean', 'ai'],
      response: 'AI (Artificial Intelligence) involves creating intelligent machines that can perform tasks requiring human intelligence. We offer AI courses with hands-on experience.'
  },
  {
      keywords: ['difference between java and python', 'java vs python', 'which is better java or python'],
      response: 'Both Java and Python are powerful languages. Java is widely used for enterprise applications, while Python is popular in AI, ML, and web development. It depends on your goals!'
  },
  {
      keywords: ['how can i improve coding', 'how to get better at coding', 'coding tips', 'tips'],
      response: 'Practice is key! Work on projects, solve coding challenges, and join our coding courses at Ahiraa Learning to enhance your skills.'
  },
  {
      keywords: ['what are loops in programming', 'loops in java', 'loops in python', 'loops'],
      response: 'Loops allow you to repeat a block of code multiple times. We cover loops in detail in both our Java and Python courses.'
  },
  {
      keywords: ['what is object-oriented programming', 'oop', 'object-oriented programming'],
      response: 'OOP (Object-Oriented Programming) is a paradigm that uses objects and classes. Our Java and Python courses focus on OOP concepts like inheritance, polymorphism, and encapsulation.'
  },
  {
      keywords: ['what is recursion', 'recursion in programming', 'recursion in java', 'recursion in python'],
      response: 'Recursion is a function that calls itself to solve smaller instances of a problem. We teach recursion in both Java and Python courses.'
  },
  {
      keywords: ['how to debug code', 'debugging tips', 'debugging help'],
      response: 'Debugging involves identifying and fixing errors in code. We provide debugging techniques and tips in our coding courses at Ahiraa Learning.'
  },
  {
      keywords: ['what are arrays', 'arrays in java', 'arrays in python'],
      response: 'An array is a collection of elements stored at contiguous memory locations. Arrays are covered in both Java and Python courses at Ahiraa Learning.'
  },
  {
      keywords: ['what are the best programming languages', 'which programming language to learn', 'most popular programming languages'],
      response: 'Java, Python, and JavaScript are among the most popular languages. At Ahiraa Learning, we offer courses in these and more to help you succeed.'
  },
  {
      keywords: ['how to manage time', 'time management tips', 'how to be productive'],
      response: 'Time management is crucial for success. Set goals, prioritize tasks, and take breaks. Join Ahiraa Learning to learn effective time management strategies in our courses.'
  },
  {
      keywords: ['how to crack coding interviews', 'coding interview tips', 'coding interview preparation', 'interview'],
      response: 'Practice problem-solving, focus on data structures, and understand algorithms. Our courses provide mock interviews and coding challenges to prepare you!'
  },
  {
      keywords: ['what is git', 'how to use git', 'git commands', 'git', 'github'],
      response: 'Git is a version control system that tracks changes in your code. We cover Git and GitHub in our Web Development courses, including version control best practices.'
  },
  {
      keywords: ['what is html', 'html language', 'html course'],
      response: 'HTML (HyperText Markup Language) is the foundation of web development. At Ahiraa Learning, we offer a comprehensive HTML course as part of our web development program.'
  },
  {
      keywords: ['css', 'what is css', 'css course'],
      response: 'CSS (Cascading Style Sheets) is used to style websites. Our Web Development course includes in-depth CSS training to create visually appealing websites.'
  },
  {
      keywords: ['javascript', 'what is javascript', 'javascript course'],
      response: 'JavaScript is a programming language used to create dynamic website content. We offer a full-stack JavaScript course at Ahiraa Learning.'
  },
  {
      keywords: ['what is blockchain', 'blockchain course', 'learn blockchain'],
      response: 'Blockchain is a decentralized technology used in cryptocurrencies. Our internship programs cover Blockchain technology as part of our advanced courses.'
  },
  {
      keywords: ['how many courses do you offer', 'total number of courses', 'available courses'],
      response: 'We offer over 10+ courses in programming, AI, ML, Web Development, and internships in various engineering and management fields.'
  },
  {
      keywords: ['difference between frontend and backend', 'frontend vs backend', 'frontend backend development'],
      response: 'Frontend refers to the client-side of a website (what users see), while backend refers to server-side operations. Our Web Development course covers both!'
  },
  {
      keywords: ['how long does it take to learn java', 'time to learn java', 'java learning time'],
      response: 'Learning Java depends on your dedication. Our Java course can be completed in 30 to 45 days, with assignments and projects to speed up your learning.'
  },
  {
      keywords: ['what is a database', 'database course', 'learn database'],
      response: 'A database is an organized collection of data. We offer Database Management courses that cover SQL, MySQL, and more!'
  },
  {
      keywords: ['difference between ai and ml', 'ai vs ml', 'which is better ai or ml'],
      response: 'AI focuses on making intelligent systems, while ML is a subset of AI that enables machines to learn from data. Both are important and covered in our courses.'
  },
  {
      keywords: ['what is cloud computing', 'cloud course', 'enroll in cloud computing'],
      response: 'Cloud Computing delivers computing services over the internet. We offer internships in Cloud Computing that include hands-on practice.'
  },
  {
      keywords: ['when do new batches start', 'batch start date', 'upcoming batches'],
      response: 'New batches for all our courses start every month. Visit our website or contact us to know the exact dates.'
  },
  {
      keywords: ['how can i pay', 'payment methods', 'how to make payment'],
      response: 'You can make payments through online banking, UPI, or credit/debit cards. Visit www.ahiraalearning.com for payment details.'
  },
  {
      keywords: ['installments', 'can i pay in installments', 'is installment payment available'],
      response: 'Yes, we offer easy installment plans for our courses. Contact us for more details on installment payments.'
  },
  {
      keywords: ['how to stay motivated', 'motivation tips', 'how to avoid procrastination'],
      response: 'Set small goals, reward yourself for progress, and stay focused on the end result. Our courses provide a structured approach to help you stay motivated.'
  },
  {
      keywords: ['does ahiraa learning provide certification', 'do i get certificates', 'certificates available', 'certificates'],
      response: 'Yes, we provide Course Completion, Coding Skill, and Performance Certificates for all our courses.'
  },
  {
      keywords: ['does ahiraa learning provide LOR', 'LOR', 'LOR available', 'do i get LOR', 'lor', 'letter of recommendation'],
      response: 'Yes, you will get LOR with performance & you need to attend a session with us.'
  },
  {
      keywords: ['what is cybersecurity', 'cybersecurity course', 'learn cybersecurity'],
      response: 'Cybersecurity focuses on protecting systems from attacks. Our Cybersecurity course covers network security, ethical hacking, and more.'
  },
  {
      keywords: ['do you provide live classes', 'are the classes live', 'live sessions'],
      response: 'Yes, we provide live interactive sessions along with recorded videos for all our courses. You can learn at your own pace.'
  },
  {
      keywords: ['what is hybrid electric vehicle', 'hev course', 'learn hybrid vehicles'],
      response: 'Hybrid Electric Vehicles (HEVs) combine a traditional engine with an electric motor. We offer internships in HEV technology for mechanical and electrical engineers.'
  }
];


// Helper function to generate bot response based on user input (case insensitive)
const generateBotResponse = (input) => {
  const lowercasedInput = input.toLowerCase(); // Convert user input to lowercase

  // Check each prebuilt response group
  for (const group of prebuiltResponses) {
    if (group.keywords.some(keyword => lowercasedInput.includes(keyword.toLowerCase()))) {
      return group.response;
    }
  }

  return "I'm here to help! Please ask any specific questions you may have.";
};

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false); // State to track if the chatbot is open
  const [messages, setMessages] = useState([]); // Messages between user and bot
  const [userInput, setUserInput] = useState(''); // User input text

  const handleSendMessage = () => {
    if (userInput.trim()) {
      const userMessage = { sender: 'user', text: userInput };
      const botResponse = { sender: 'bot', text: generateBotResponse(userInput) };

      setMessages([...messages, userMessage, botResponse]);
      setUserInput(''); // Clear input field after sending
    }
  };

  return (
    <div className="new-chatbot-container">
      {isOpen ? (
        <div className="new-chatbox">
          <div className="new-chat-header">
            <h4 style={{color:'white'}}>Ahiraa Learning Bot</h4>
            <button className="new-close-btn" onClick={() => setIsOpen(false)}>X</button>
          </div>
          <div className="new-chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`new-message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="new-chat-input">
            <input
              type="text"
              placeholder="Ask something..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      ) : (
        <div className="new-chatbot-icon" onClick={() => setIsOpen(true)}>
          <img src="./LogoHead.png" alt="Chatbot" className="new-chatbot-logo" />
        </div>
      )}
      <h6 className="new-chatbot-tagline">Chat with Us!</h6>
    </div>
  );
};

export default Chatbot;
