import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import bankimg from './Images/b1.jpg';
import education from './Images/ed.jpg';
import healthcare from './Images/hl.jpg';
import logistics from './Images/lo.png';
import Media from './Images/mdia.jpg';
import market from './Images/mar.png';
import manufacturing from './Images/mf.jpg';
import publics from './Images/pcs.jpg';
import Retail from './Images/re.jpg';
import insurance from './Images/ins.jpg';
import energy from './Images/e3.jpg';
import './CustomIndustrySlider.css';

const CustomIndustrySlider = () => {
  const navigate = useNavigate();

  const industries = [
    { name: 'Digital Banking', image: bankimg },
    { name: 'Smart Retail', image: Retail },
    { name: 'Healthcare Innovation', image: healthcare },
    { name: 'Next-Gen Manufacturing', image: manufacturing },
    { name: 'Capital Market Solutions', image: market },
    { name: 'Tech-Enabled Education', image: education },
    { name: 'Energy & Sustainability', image: energy },
    { name: 'Logistics & Supply Chain', image: logistics },
    { name: 'Media & Entertainment', image: Media },
    { name: 'Insurance Innovation', image: insurance },
    { name: 'Life Sciences', image: bankimg },
    { name: 'Public Services Transformation', image: publics },
  ];

  const handleShowMore = () => {
    navigate('/IndustrySelection');
  };

  return (
    <div className="custom-industry-slider">
      <h2 className="custom-slider-heading text-center mb-4">See More of Our Services</h2>
      <div className="custom-slider-inner">
        {industries.slice(0, 3).map((industry, index) => (
          <div className="custom-industry-card" key={index}>
            <img src={industry.image} alt={`${industry.name}`} className="custom-card-img" />
            <h5 className="custom-card-title">{industry.name}</h5>
            <Link to={`/industry/${industry.name}`} className="btn btn-primary custom-btn-learn-more">Learn More</Link>
          </div>
        ))}
      </div>
      <button onClick={handleShowMore} className="toggle-button">
        Show More
        <span className="toggle-icon">
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 15L7 10H17L12 15Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default CustomIndustrySlider;
