import React, { useState } from "react";
import './ContactForm.css';

function ContactForm() {
  const [profileSource, setProfileSource] = useState("");
  const [otherSource, setOtherSource] = useState("");
  const [helpMessage, setHelpMessage] = useState("");
  const maxChars = 500;

  const [formData, setFormData] = useState({
    fullName: "",
    position: "",
    companyName: "",
    email: "",
    contactNumber: "",
    purpose: "",
    profileSource: "",
    otherSource: "",
    helpMessage: ""
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleProfileSourceChange = (e) => {
    setProfileSource(e.target.value);
    handleInputChange(e);
  };

  const handleHelpMessageChange = (e) => {
    setHelpMessage(e.target.value.slice(0, maxChars));
    handleInputChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/backend/save_form.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert("Form submitted successfully!");
      } else {
        alert("There was an error submitting the form.");
      }
    })
    .catch(error => {
      console.error("Error:", error);
      alert("There was an error submitting the form.");
    });
  };

  return (
    <div className="Cont">
      <div className="contact-container">
        <h2 className="contact-heading text-center mb-4">Contact Us</h2>
        <form onSubmit={handleSubmit} className="contact-form p-4 shadow-sm rounded bg-light">
          <div className="contact-form-group form-group">
            <label htmlFor="fullName">Full Name</label>
            <input type="text" className="form-control contact-input" id="fullName" value={formData.fullName} onChange={handleInputChange} placeholder="Enter your full name" required />
          </div>

          <div className="contact-form-group form-group">
            <label htmlFor="position">Position</label>
            <input type="text" className="form-control contact-input" id="position" value={formData.position} onChange={handleInputChange} placeholder="Your Position" required />
          </div>

          <div className="contact-form-group form-group">
            <label htmlFor="companyName">Company Name</label>
            <input type="text" className="form-control contact-input" id="companyName" value={formData.companyName} onChange={handleInputChange} placeholder="Company Name" required />
          </div>

          <div className="contact-form-group form-group">
            <label htmlFor="email">Email Address</label>
            <input type="email" className="form-control contact-input" id="email" value={formData.email} onChange={handleInputChange} placeholder="Email ID" required />
          </div>

          <div className="contact-form-group form-group">
            <label htmlFor="contactNumber">Contact Number</label>
            <input type="tel" className="form-control contact-input" id="contactNumber" value={formData.contactNumber} onChange={handleInputChange} placeholder="Contact Number" required />
          </div>

          <div className="contact-form-group form-group">
            <label htmlFor="purpose">Purpose</label>
            <select className="form-control contact-select" id="purpose" value={formData.purpose} onChange={handleInputChange} required>
              <option value="">Select Purpose</option>
              <option>Appointment</option>
              <option>Project Handling</option>
              <option>Manpower Support</option>
              <option>Collaboration</option>
              <option>Partnership</option>
              <option>Events and Meeting</option>
              <option>Tie Up</option>
            </select>
          </div>

          <div className="contact-form-group form-group">
            <label htmlFor="profileSource">Where did you see our company profile?</label>
            <select className="form-control contact-select" id="profileSource" value={profileSource} onChange={handleProfileSourceChange} required>
              <option value="">Select an option</option>
              <option>Social Media</option>
              <option>Ahiraa Portal</option>
              <option>Referral</option>
              <option>Gmail</option>
              <option>Event Organizations</option>
              <option>Other</option>
            </select>
          </div>

          {profileSource === "Other" && (
            <div className="contact-form-group form-group">
              <label htmlFor="otherSource">Please specify</label>
              <input type="text" className="form-control contact-input" id="otherSource" value={otherSource} onChange={(e) => setOtherSource(e.target.value)} placeholder="Specify other source" required />
            </div>
          )}

          <div className="contact-form-group form-group">
            <label htmlFor="helpMessage">How can we help you?</label>
            <textarea className="form-control contact-textarea" id="helpMessage" rows="4" value={helpMessage} onChange={handleHelpMessageChange} placeholder="Enter your message (up to 500 characters)" required />
            <small className="text-muted">{maxChars - helpMessage.length} characters remaining</small>
          </div>

          <button type="submit" className="btn btn-primary contact-submit-button btn-block">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
