import React, { useState, useEffect } from 'react';
import './EmpDetails.css';
import axios from 'axios';  // Assuming you are using Axios to fetch data from a PHP backend

const EmpDetails = () => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch employee data from PHP backend
    useEffect(() => {
        axios.get('http://your-backend-url/employee-targets.php')  // Replace with your actual backend URL
            .then(response => {
                setEmployees(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    // Sort employees based on target reached
    const sortedEmployees = [...employees].sort((a, b) => b.targetReached - a.targetReached).slice(0, 5);

    return (
        <div className="dashboard">
            <h1>Employee Target Dashboard</h1>
            <table>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Target Reached</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedEmployees.map((employee, index) => (
                        <tr key={employee.id}>
                            <td>{index + 1}</td>
                            <td>{employee.name}</td>
                            <td>{`$${employee.targetReached.toLocaleString()}`}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EmpDetails;
