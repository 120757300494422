import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const CybersecurityAnalyst = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Cybersecurity Analyst</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-CSA-192521</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Cybersecurity Analyst</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Monitor the company’s networks and systems for security breaches or incidents, responding swiftly to mitigate risks.</li>
                    <li>Conduct vulnerability assessments, penetration testing, and security audits to ensure systems are robust and secure.</li>
                    <li>Develop and implement security policies, procedures, and guidelines to safeguard the organization’s digital assets.</li>
                    <li>Investigate security alerts, determine root causes, and implement corrective actions.</li>
                    <li>Collaborate with IT teams to ensure security protocols are integrated into system architectures and processes.</li>
                    <li>Stay updated on the latest cybersecurity threats, vulnerabilities, and best practices to protect the organization’s infrastructure.</li>
                    <li>Prepare reports and documentation related to security incidents and mitigation strategies.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Proficiency in cybersecurity tools (firewalls, IDS/IPS, SIEM systems) and protocols (SSL/TLS, VPN, encryption techniques).</li>
                    <li>Strong understanding of network security, vulnerability management, and incident response.</li>
                    <li>Experience with security frameworks such as NIST, ISO 27001, and risk management processes.</li>
                    <li>Familiarity with cloud security (AWS, Azure) and endpoint security solutions.</li>
                    <li>Experience in threat modeling, ethical hacking, and malware analysis.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Excellent problem-solving and analytical skills, with the ability to assess and manage risks.</li>
                    <li>Strong communication and documentation skills, capable of working in cross-functional teams.</li>
                    <li>Proactive, detail-oriented, and able to stay calm under pressure during security incidents.</li>
                    <li>Ability to stay current with emerging security trends and technologies.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s or Master’s degree in Computer Science, Information Technology, or a related field.</p>
                <p>Relevant certifications such as CISSP, CISM, CEH, or equivalent are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default CybersecurityAnalyst;
