import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const ProductManager = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Product Manager</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>5+ Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-PM-192523</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Product Manager</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
               
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Drive the product development lifecycle from concept to launch, ensuring alignment with business objectives and customer needs.</li>
                    <li>Define and prioritize product features, translating market trends and customer feedback into a clear product roadmap.</li>
                    <li>Collaborate with cross-functional teams, including engineering, design, and marketing, to deliver high-quality products on time.</li>
                    <li>Conduct market research and competitive analysis to identify opportunities for product innovation and improvement.</li>
                    <li>Monitor product performance post-launch, gathering data to measure success and making adjustments where necessary.</li>
                    <li>Act as the primary point of contact for stakeholders, providing clear communication on product vision, strategy, and updates.</li>
                    <li>Manage the product backlog, ensuring that development teams have clear, actionable tasks aligned with the product vision.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Strong understanding of product management frameworks, agile methodologies, and product lifecycle management.</li>
                    <li>Familiarity with product analytics tools and customer feedback platforms.</li>
                    <li>Experience working closely with engineering teams to develop scalable, user-centric products.</li>
                    <li>Ability to create detailed product requirements, user stories, and acceptance criteria.</li>
                    <li>Experience with product marketing, go-to-market strategies, and customer engagement techniques.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong leadership, communication, and project management skills.</li>
                    <li>Analytical mindset with a data-driven approach to decision-making.</li>
                    <li>Problem-solving skills with the ability to balance customer needs, business goals, and technical constraints.</li>
                    <li>Ability to work in a fast-paced environment and manage multiple priorities.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s or Master’s degree in Business, Engineering, Computer Science, or a related field.</p>
                <p>MBA or relevant certifications in product management are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default ProductManager;
