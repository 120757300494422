import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const WebDeveloper = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Web Developer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>2-4 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-WD-192525</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Web Developer</p>
                </div>
                <div className="col-md-12">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Design, develop, and maintain functional and visually appealing websites and web applications.</li>
                    <li>Collaborate with UX/UI designers to implement user-friendly interfaces and enhance user experience.</li>
                    <li>Write clean, scalable, and efficient code using best practices and standards.</li>
                    <li>Integrate data from various back-end services and databases, ensuring seamless functionality.</li>
                    <li>Troubleshoot, test, and optimize applications for maximum speed and scalability.</li>
                    <li>Stay up-to-date with emerging web technologies and trends to ensure the website remains current and competitive.</li>
                    <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Proficiency in HTML, CSS, JavaScript, and frameworks like React.js or Angular.</li>
                    <li>Familiarity with server-side languages such as PHP, Python, or Node.js.</li>
                    <li>Experience with responsive design and mobile-first development practices.</li>
                    <li>Knowledge of version control systems like Git for collaborative development.</li>
                    <li>Understanding of web security principles and best practices.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong problem-solving skills and attention to detail.</li>
                    <li>Ability to work independently as well as in a team environment.</li>
                    <li>Knowledge of SEO principles and how they affect website design.</li>
                    <li>Excellent communication skills to effectively collaborate with team members and stakeholders.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Web Development, or a related field.</p>
                <p>Relevant certifications in web development or programming are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default WebDeveloper;
