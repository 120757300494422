import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const NetworkEngineer = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Network Engineer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
           
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-NE-192519</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Network Engineer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Design, implement, and manage the organization’s network infrastructure to ensure seamless connectivity.</li>
                    <li>Monitor network performance, troubleshoot issues, and resolve network outages promptly.</li>
                    <li>Perform network maintenance, upgrades, and patches to ensure network security and availability.</li>
                    <li>Collaborate with IT and infrastructure teams to implement network solutions for new projects and expansions.</li>
                    <li>Maintain documentation of network configurations, procedures, and troubleshooting guides.</li>
                    <li>Ensure the network infrastructure adheres to security standards and best practices.</li>
                    <li>Provide technical support and expertise for network-related issues across the organization.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: TCP/IP, LAN/WAN, VPN, firewalls, routing and switching.</li>
                    <li>Experience with network hardware such as routers, switches, and firewalls (e.g., Cisco, Juniper).</li>
                    <li>Familiarity with network monitoring tools and techniques (e.g., Wireshark, Nagios, SolarWinds).</li>
                    <li>Strong understanding of network security protocols and best practices.</li>
                    <li>Experience with wireless networking (Wi-Fi) and VPN configuration.</li>
                    <li>Knowledge of cloud-based networking solutions (AWS, Azure, GCP) is a plus.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong analytical and troubleshooting skills to identify and resolve complex network issues.</li>
                    <li>Ability to work independently and as part of a team.</li>
                    <li>Strong communication skills to collaborate with cross-functional teams.</li>
                    <li>Willingness to stay updated with the latest networking technologies and trends.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Information Technology, or related field.</p>
                <p>Relevant certifications such as CCNA, CCNP, or equivalent are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply ">Apply Now</a>
            </div>
        </div>
    );
};

export default NetworkEngineer;
