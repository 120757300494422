import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const JavaDeveloper = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Java Developer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-DEV-192509</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Java Developer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Work collaboratively as part of the delivery team, ensuring smooth design, development, validation, and support activities for our clients.</li>
                    <li>Engage with stakeholders to gather and understand project requirements, effectively translating them into system requirements.</li>
                    <li>Contribute to project estimations, providing accurate insights to technology leads and project managers.</li>
                    <li>Design and build efficient, scalable applications to meet clients’ digital transformation needs.</li>
                    <li>Conduct code reviews to ensure adherence to coding standards and best practices.</li>
                    <li>Collaborate with cross-functional teams to integrate user-facing elements using server-side logic.</li>
                    <li>Participate in troubleshooting and debugging processes to enhance application performance.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li><strong>Primary Skills:</strong> Java, JDBC, JSP, Servlets, Spring Boot.</li>
                    <li>Proficient in HTML, CSS, JavaScript, Bootstrap, and React.js.</li>
                    <li>Understanding of JEE2 design patterns and their application.</li>
                    <li>Familiarity with Spring frameworks and Microservices architecture.</li>
                    <li>Strong knowledge of design principles and software architecture.</li>
                    <li>Understanding of performance engineering techniques.</li>
                    <li>Experience with software quality processes and project estimation techniques.</li>
                    <li>Ability to translate functional requirements into detailed system specifications.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Familiarity with Software Development Life Cycle (SDLC) and Agile methodologies.</li>
                    <li>Strong problem-solving skills and a collaborative mindset.</li>
                    <li>Knowledge of the latest technologies and trends in software development.</li>
                    <li>Excellent communication skills to facilitate effective collaboration.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s or Master’s degree in Computer Science, Engineering, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default JavaDeveloper;
