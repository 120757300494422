import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const DevOpsEngineer = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">DevOps Engineer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-DE-192513</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>DevOps Engineer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Manage and optimize the company’s infrastructure and deployment pipelines.</li>
                    <li>Collaborate with development teams to automate and streamline operations and processes.</li>
                    <li>Implement CI/CD practices to enhance software delivery and deployment efficiency.</li>
                    <li>Monitor system performance, troubleshoot issues, and maintain system health.</li>
                    <li>Ensure security, availability, and scalability of cloud-based systems.</li>
                    <li>Document processes, configurations, and system changes for reference and training.</li>
                    <li>Stay updated with industry trends and emerging technologies to improve existing processes.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: CI/CD, Docker, Kubernetes, Jenkins, AWS/Azure.</li>
                    <li>Proficiency in scripting languages such as Python, Bash, or Ruby.</li>
                    <li>Strong understanding of Linux/Unix systems and networking concepts.</li>
                    <li>Familiarity with configuration management tools (e.g., Ansible, Chef, Puppet).</li>
                    <li>Knowledge of monitoring tools (e.g., Nagios, Prometheus, Grafana).</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong problem-solving skills and a proactive approach to challenges.</li>
                    <li>Excellent communication and collaboration skills to work effectively within teams.</li>
                    <li>Ability to manage multiple tasks and prioritize workload in a fast-paced environment.</li>
                    <li>A passion for automation and continuous improvement.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Engineering, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default DevOpsEngineer;
