import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Provider.css';

function Provider() {
  return (
    <div className='MBg2'>
      <div className="why-to-choose">
        <Container>
          <h2 className="text-center mb-5 with-background-image">Why to Choose Ahiraa Learning</h2>
          <Row>
            <Col xs={6} sm={6} className="mb-4">
              <div className="reason transparent-card">
                <h3>Comprehensive IT Training & Internships</h3>
                <p>
                Our programs provide hands-on training, IT project development, real-time project building, and internships for real-world experience, along with placement assistance to secure roles in top product-based companies.
                </p>
              </div>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <div className="reason transparent-card">
                <h3>Expert Mentorship from Prestigious Institutions</h3>
                <p>
                Our mentors from prestigious institutions like IIT and IIM provide personalized guidance to enhance your learning. With their expert support, you'll gain valuable insights throughout the course. We ensure a tailored and enriching learning experience.                </p>
              </div>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <div className="reason transparent-card">
                <h3>Collaboration with Renowned Universities and Institutions</h3>
                <p>
                  We collaborate with prestigious institutions such as IIT Bombay, IIT Madras, and others, adding credibility to our programs.
                </p>
              </div>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <div className="reason transparent-card">
                <h3>Practical Skills Development and Career Opportunities</h3>
                <p>
                  Through real-time projects and career support services, we help you develop practical skills and enhance your career prospects.
                </p>
              </div>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <div className="reason transparent-card">
                <h3>Flexibility and Convenience</h3>
                <p>
                  We offer flexible class timings and provide both live and recorded sessions to accommodate your schedule and learning pace.
                </p>
              </div>
            </Col>
            <Col xs={6} sm={6} className="mb-4">
              <div className="reason transparent-card">
                <h3>Placement and Approvals</h3>
                <p> 
                We provide industry-approved placement support with programs tailored to meet the demands of today's industrial and IT job market.    
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Provider;