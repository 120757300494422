import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const DataScientist = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Data Scientist</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-DS-192524</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Data Scientist</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Analyze complex datasets to extract actionable insights, guiding business decisions and strategies.</li>
                    <li>Design, develop, and implement predictive models using advanced statistical and machine learning techniques.</li>
                    <li>Collaborate with cross-functional teams to understand business problems and provide data-driven solutions.</li>
                    <li>Clean, process, and structure large datasets to ensure accuracy and reliability in analysis.</li>
                    <li>Present findings and recommendations to stakeholders through data visualization and reporting tools.</li>
                    <li>Work closely with engineering teams to deploy models in production and ensure scalability.</li>
                    <li>Continuously monitor the performance of deployed models and refine them based on feedback and new data.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Strong proficiency in programming languages such as Python or R.</li>
                    <li>Experience with machine learning algorithms, statistical modeling, and predictive analytics.</li>
                    <li>Familiarity with data analysis libraries like Pandas, NumPy, Scikit-learn, and TensorFlow.</li>
                    <li>Strong understanding of databases (SQL, NoSQL) and big data technologies like Hadoop or Spark.</li>
                    <li>Expertise in data visualization tools (Tableau, Power BI, Matplotlib) for creating meaningful visual representations of data.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Excellent problem-solving abilities and the capacity to work with large, unstructured datasets.</li>
                    <li>Knowledge of cloud platforms (AWS, Azure, Google Cloud) for scalable data processing.</li>
                    <li>Strong communication and presentation skills, with the ability to translate technical findings into business insights.</li>
                    <li>Continuous learner, staying updated with the latest trends in data science and AI.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s or Master’s degree in Data Science, Statistics, Computer Science, or a related field.</p>
                <p>Relevant certifications in data science, machine learning, or AI are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default DataScientist;
