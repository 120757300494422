import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const CloudArchitect = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Cloud Architect</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>5-7 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-CA-192517</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Cloud Architect</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Design and implement scalable and secure cloud solutions to meet business needs.</li>
                    <li>Collaborate with cross-functional teams to define cloud architecture and ensure alignment with company strategies.</li>
                    <li>Evaluate and recommend cloud services and technologies for optimal performance and cost-effectiveness.</li>
                    <li>Monitor and manage cloud environments to ensure high availability, reliability, and performance.</li>
                    <li>Provide guidance and support for cloud migration projects and best practices.</li>
                    <li>Stay updated on industry trends and emerging technologies related to cloud computing.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: AWS, Azure, Google Cloud Platform (GCP), cloud security principles.</li>
                    <li>Proficiency in cloud architecture patterns and services (compute, storage, networking).</li>
                    <li>Experience with infrastructure as code (IaC) tools such as Terraform or CloudFormation.</li>
                    <li>Strong knowledge of containerization technologies (Docker, Kubernetes) is a plus.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Excellent problem-solving and analytical skills.</li>
                    <li>Strong communication skills to effectively collaborate with technical and non-technical stakeholders.</li>
                    <li>Ability to lead and mentor teams in cloud practices and methodologies.</li>
                    <li>Strong project management skills to oversee cloud initiatives.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Information Technology, or a related field.</p>
                <p>Relevant cloud certifications (e.g., AWS Certified Solutions Architect, Azure Solutions Architect Expert) are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default CloudArchitect;
