import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const FullStackDeveloper = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Full-Stack Developer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
           
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-FSD-192520</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Full-Stack Developer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Develop and maintain both the front-end and back-end of web applications, ensuring a seamless user experience.</li>
                    <li>Collaborate with UI/UX designers and backend developers to create functional and visually appealing interfaces.</li>
                    <li>Implement server-side logic, database integration, and optimize APIs for performance and scalability.</li>
                    <li>Write clean, efficient, and well-documented code, ensuring the software meets business and technical requirements.</li>
                    <li>Troubleshoot, debug, and upgrade software, ensuring high performance and responsiveness across all platforms.</li>
                    <li>Stay updated on emerging technologies, implementing best practices to continually enhance product functionality.</li>
                    <li>Perform unit testing and debugging to ensure application stability.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Proficiency in front-end technologies (HTML, CSS, JavaScript) and frameworks (React.js, Angular, Vue.js).</li>
                    <li>Experience with back-end technologies (Node.js, Java, Python) and databases (MySQL, PostgreSQL, MongoDB).</li>
                    <li>Knowledge of RESTful APIs, web services, and microservices architecture.</li>
                    <li>Familiarity with version control systems such as Git and deployment processes using CI/CD pipelines.</li>
                    <li>Strong understanding of web application security and performance optimization.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Excellent problem-solving skills and the ability to work in a collaborative environment.</li>
                    <li>Ability to manage multiple tasks and projects simultaneously, ensuring timely delivery.</li>
                    <li>Strong attention to detail and focus on quality assurance.</li>
                    <li>Familiarity with Agile methodologies and project management tools like Jira or Trello.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor's or Master’s degree in Computer Science, Engineering, or a related field.</p>
                <p>Relevant certifications in Full-Stack Development or related technologies are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default FullStackDeveloper;
