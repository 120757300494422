import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const GeneralManager = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">General Manager</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>5-10 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-GM-192510</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>General Manager</p>
                </div>
                <div className="col-md-12">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Oversee all business operations, ensuring smooth and efficient processes across various departments.</li>
                    <li>Develop and implement strategic plans to achieve company objectives and enhance profitability.</li>
                    <li>Manage resources effectively, including budgeting, forecasting, and financial planning.</li>
                    <li>Foster a culture of continuous improvement and operational excellence within the organization.</li>
                    <li>Collaborate with department heads to align their strategies with the overall business objectives.</li>
                    <li>Monitor performance metrics and make data-driven decisions to drive growth and improve performance.</li>
                    <li>Lead, mentor, and develop team members to build a high-performing workforce.</li>
                    <li>Establish and maintain relationships with key stakeholders, clients, and partners.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Strong understanding of business management principles and practices.</li>
                    <li>Proficiency in financial management, project management, and strategic planning.</li>
                    <li>Familiarity with performance management systems and operational metrics.</li>
                    <li>Ability to analyze complex data and translate findings into actionable strategies.</li>
                    <li>Experience with budgeting and resource allocation.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Excellent leadership and team management skills.</li>
                    <li>Strong communication and interpersonal abilities.</li>
                    <li>Problem-solving skills and a proactive approach to challenges.</li>
                    <li>Ability to thrive in a fast-paced, dynamic environment.</li>
                    <li>Knowledge of the latest industry trends and best practices.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s or Master’s degree in Business Administration, Management, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default GeneralManager;
