import React from 'react';
import './ContactUs.css'
import DetailsPage from './DetailsPage';
import SocialMedia from './SocialMedia';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Navbar123 from './Navbar123';

function ContactUs() {
    return (
        <div className="contact">
               <Navbar123/>
            <h2 className="contact-heading">Contact Us</h2>
           
            <div className='row'>
                <div className='col'>
                <h2 className="contact-subheading">Phone.No</h2>
            <p className="contact-paragraph">9390808501, 8106630124</p></div>
                <div className='col'>
                <h2 className="contact-subheading">Email ID</h2>
                <a href="mailto:ahiraalearning.helpline@gmail.com"><p className="contact-paragraph">ahiraalearning.helpline@gmail.com</p> </a></div>
            </div>
            <DetailsPage />
            <SocialMedia className="social-media-section" />
            
        </div>
    );
}

export default ContactUs;
