import React from 'react';
import './TeamCards.css';
// import imgMahee from './ImgPic/Maahee.jpg';
import imgMahee from './ImgPic/MaheeBG.png';
// import imgAshok from './ImgPic/Me1.jpg';
import imgAshok from './ImgPic/Me2.png';
// import imgNissar from './ImgPic/NissarPic.jpg';
import imgNissar from './ImgPic/NissarPic1.png';
// import imgVijay from './ImgPic/VijayPic1.jpg';
import imgVijay from './ImgPic/VijayPic2.png';

function TeamCards() {
    const teamMembers = [
        {
            name: 'P. Khaja Baba Raja Mohiddin',
            title: 'Founder & CEO',
            description: 'Drives the overall vision, strategic direction, and growth of the organization, with a strong focus on integrating IT and education to empower learners.',
            image: imgMahee
        },
        {
            name: 'M. Ashok',
            title: 'Technology Director',
            description: 'Oversees all aspects of IT services and infrastructure, ensuring seamless project execution and innovation in technological solutions for educational excellence.',
            image: imgAshok
        },
        {
            name: 'P. Mohammad Nisar',
            title: 'Co-Founder',
            description: 'A visionary leader who plays a pivotal role in shaping the company’s strategies and initiatives, driving innovation and collaboration within the organization.',
            image: imgNissar
        },
        {
            name: 'M. Vijay',
            title: 'Managing Director',
            description: 'Responsible for managing and controlling the overall operations of the organization, ensuring alignment with business goals and delivering exceptional results.',
            image: imgVijay
        }
    ];
    
    return (
        <div className="unique-team-cards">
        {teamMembers.map((member, index) => (
            <div key={index} className="unique-card">
                <div className="unique-card-image-container">
                    <img src={member.image} alt={member.title} className="unique-card-image" />
                </div>
                <div className="unique-card-content">
                    <h3 className="unique-card-name">{member.name}</h3>
                    <p className="unique-card-title" style={{ fontWeight: 'bold' }}>
                        {member.title}
                    </p>
                    <p className="unique-card-description">{member.description}</p>
                </div>
            </div>
        ))}
    </div>
    
    );
}

export default TeamCards;
