import React from 'react';
import './AboutUs.css'; // Importing the CSS file for styles
import SocialMedia from './SocialMedia';
import DetailsPage from './DetailsPage';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TeamCards from './TeamCards';
import Navbar123 from './Navbar123';

function AboutUs() {
    return (
      <div className='AboutBg'>

        <div>
       <Navbar123/>

        <div className="about-page">
            <header className="about-header">
                <h1>About Us</h1>
            </header>

            <section className="about-content">
                <p>
                    The company encompasses a comprehensive range of educational endeavors aimed at enriching learning experiences and supporting academic and professional growth. This includes the provision of specialized educational programs and courses tailored to individual needs, ranging from vocational training and language proficiency courses to niche skill development programs.
                </p>

                <p>
                    The company offers personalized tutoring, coaching, and mentoring services across various subjects and disciplines, ensuring that students receive the guidance they need to excel academically. Through workshops, seminars, and training sessions, the company facilitates knowledge exchange and skill acquisition, catering to both individuals and organizations seeking professional development opportunities.
                </p>

                <p>
                    Moreover, the company provides consultancy and advisory services to educational institutions, offering expertise in curriculum development, accreditation processes, and educational policy matters. Leveraging educational technology solutions and digital learning resources, the company enhances learning experiences and accessibility, promoting innovation and engagement in education.
                </p>

                <p>
                    Administrative and operational support services are extended to educational institutions, optimizing efficiency and compliance with regulatory standards. Collaborative initiatives with educational stakeholders, government agencies, and industry partners drive forward educational reform efforts and address pressing challenges facing the sector.
                </p>

                <p>
                    Research and development activities further the company's commitment to advancing knowledge, exploring innovative teaching methodologies, and informing evidence-based practices in education. Additionally, the company offers career counseling and student support services to facilitate academic and career advancement, fostering a holistic approach to education and lifelong learning.
                </p>
                <p>
                We deliver comprehensive IT solutions tailored to a variety of industries, enhancing efficiency and driving innovation. In Digital Banking, our AI-driven banking solutions transform financial services, while Smart Retail leverages omni-channel experiences to elevate customer engagement. Our Healthcare Innovation services focus on telemedicine and patient care systems, and Next-Gen Manufacturing incorporates IoT-powered automation for streamlined production. For the Capital Markets, we provide advanced analytics to support strategic investment decisions.                </p>
                <p>
                Our Tech-Enabled Education offerings include AI-based learning platforms designed to personalize and enrich the learning experience. Energy & Sustainability solutions promote green energy initiatives, while Logistics & Supply Chain services use blockchain for transparent tracking. In Media & Entertainment, we enable immersive streaming platforms, and Insurance Innovation features digital transformations to optimize claim processing. Life Sciences solutions offer robust clinical data management, and Public Services Transformation includes developing citizen service platforms to enhance public sector efficiency                </p>

            </section>
        </div>
        </div>
        <TeamCards/>
        <DetailsPage/>
        <SocialMedia/>
      </div>
    );
}

export default AboutUs;
