import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const UIUXDesigner = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">UI/UX Designer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>2-4 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-UX-192514</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>UI/UX Designer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Design and create user-friendly interfaces for web and mobile applications.</li>
                    <li>Conduct user research and usability testing to gather feedback and insights.</li>
                    <li>Collaborate with product managers and developers to define and implement innovative solutions.</li>
                    <li>Develop wireframes, prototypes, and high-fidelity designs that enhance user experience.</li>
                    <li>Ensure consistency in design across various platforms and devices.</li>
                    <li>Stay updated with industry trends, design tools, and best practices to maintain a competitive edge.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: UI Design, UX Design, Prototyping, User Research.</li>
                    <li>Proficiency in design tools such as Sketch, Adobe XD, Figma, or InVision.</li>
                    <li>Strong understanding of responsive design principles and mobile-first design.</li>
                    <li>Familiarity with HTML, CSS, and basic front-end development concepts is a plus.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Excellent communication skills to articulate design ideas and concepts.</li>
                    <li>Strong problem-solving abilities and attention to detail.</li>
                    <li>Ability to work collaboratively in a team environment and manage multiple projects simultaneously.</li>
                    <li>Passion for creating engaging and visually appealing user experiences.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Design, Human-Computer Interaction, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default UIUXDesigner;
