import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const SoftwareTester = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Software Tester</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>2-4 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-ST-192512</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Software Tester</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Execute test cases (manual and automated) to ensure software quality and functionality.</li>
                    <li>Collaborate with development teams to understand project requirements and design testing strategies.</li>
                    <li>Identify, document, and track defects and issues throughout the testing process.</li>
                    <li>Conduct regression testing to verify fixes and enhancements.</li>
                    <li>Prepare detailed test reports and communicate findings to stakeholders.</li>
                    <li>Develop and maintain test scripts and testing frameworks.</li>
                    <li>Stay updated with industry best practices and testing methodologies.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Manual Testing, Automated Testing, Test Case Design.</li>
                    <li>Proficiency in testing tools such as Selenium, JUnit, or TestNG.</li>
                    <li>Familiarity with defect tracking tools (e.g., JIRA, Bugzilla).</li>
                    <li>Basic knowledge of programming languages like Python or Java is a plus.</li>
                    <li>Understanding of database concepts and SQL for backend testing.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong analytical and problem-solving skills.</li>
                    <li>Excellent attention to detail and a thorough approach to testing.</li>
                    <li>Good communication skills for effective collaboration with team members.</li>
                    <li>Ability to work independently and manage time effectively in a fast-paced environment.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Information Technology, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default SoftwareTester;
