import React from 'react';
import { Link } from 'react-router-dom';
import './IndustrySelection.css';
import imgimg from './ImgImg/rssp2.jpeg';
import Banking from './OrgImg/Bnk.jpg';
import Retail from './OrgImg/ret.jpg';
import Healthcare from './OrgImg/Doct.jpg';
import Manufacturing from './OrgImg/manufc.jpg';
import Market from './OrgImg/market.jpg';
import Education from './OrgImg/edc.jpg';
import Energy from './OrgImg/WINDp.jpg';
import Logistics from './OrgImg/Logicstics.png';
import Media from './OrgImg/enterpri.jpg';
import Insurance from './OrgImg/insurs.jpg';
import Public from './OrgImg/pbs3 - Copy.jpg';
import Life from './OrgImg/Lifesc.jpg';
import SocialMedia from '../Home/SocialMedia';

const IndustrySelection = () => {
  const industries = [
    { name: 'Digital Banking', project: 'AI-driven banking solutions', image: Banking },
    { name: 'Smart Retail', project: 'Omni-channel customer experiences', image: Retail },
    { name: 'Healthcare Innovation', project: 'Telemedicine and patient care systems', image: Healthcare },
    { name: 'Next-Gen Manufacturing', project: 'IoT-powered automation', image: Manufacturing },
    { name: 'Capital Market Solutions', project: 'Advanced analytics for investment strategies', image: Market },
    { name: 'Tech-Enabled Education', project: 'AI-based learning platforms', image: Education },
    { name: 'Energy & Sustainability', project: 'Green energy initiatives', image: Energy },
    { name: 'Logistics & Supply Chain', project: 'Blockchain-based supply chain tracking', image: Logistics },
    { name: 'Media & Entertainment', project: 'Streaming platforms and immersive media', image: Media },
    { name: 'Insurance Innovation', project: 'Digital transformation for claim processing', image: Insurance },
    { name: 'Life Sciences', project: 'Clinical data management solutions', image: Life },
    { name: 'Public Services Transformation', project: 'Citizen services platforms', image: Public }
  ];

  return (
    <>
    <div className="industry-container">
      <h2 className="industry-header text-center">
        Select Your Industry. Discover Our Impact & Services.
      </h2>

      <div className="industry-grid">
        {industries.map((industry, index) => (
          <div className="industry-card" key={index}>
            <Link to={`/industry/${industry.name}`}>
              <div className="image-wrapper">
                <img src={industry.image} alt={industry.name} className="industry-card-img" />
                <div className="gradient-overlay"></div>
                <div className="industry-card-body">
                  <h5 className="industry-card-title">{industry.name}</h5>
                  <p className="industry-card-text">{industry.project}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
    <SocialMedia/>
    </>
  );
};

export default IndustrySelection;
