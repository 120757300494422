import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const PythonDeveloper = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Python Developer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
           
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-PY-192511</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Python Developer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Develop, test, and deploy web applications and backend services using Python.</li>
                    <li>Collaborate with cross-functional teams to gather and analyze requirements for new features and enhancements.</li>
                    <li>Design and implement RESTful APIs to support frontend applications.</li>
                    <li>Write reusable, testable, and efficient code while adhering to best practices.</li>
                    <li>Troubleshoot and debug applications to optimize performance and user experience.</li>
                    <li>Participate in code reviews to maintain high standards of quality and share knowledge with team members.</li>
                    <li>Stay updated with emerging technologies and incorporate them into the development process.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Python, Django, Flask, RESTful APIs.</li>
                    <li>Proficient in HTML, CSS, and JavaScript for web development.</li>
                    <li>Strong understanding of database management systems (e.g., PostgreSQL, MySQL).</li>
                    <li>Familiarity with version control systems, especially Git.</li>
                    <li>Understanding of software development methodologies such as Agile and Scrum.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Excellent problem-solving skills and ability to work collaboratively in a team.</li>
                    <li>Strong communication skills to facilitate effective interaction with stakeholders.</li>
                    <li>Ability to adapt to new technologies and frameworks quickly.</li>
                    <li>Knowledge of testing frameworks (e.g., PyTest, UnitTest) and continuous integration/continuous deployment (CI/CD) practices.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s or Master’s degree in Computer Science, Engineering, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default PythonDeveloper;
