import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Subject.css'; // Import CSS file for styling

function Subjects() {
  return (
    <div className='MBg4'>
      <Container>
        <h1 className="text-center mb-4 heading">Engineering & Management Domains</h1>
        <Row className="justify-content-center">
          {/* CSE card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/cse" className="text-decoration-none">
              <div className="card h-100 cse-card">
                <div className="card-body text-center">
                  <h5 className="card-title">CSE</h5>
                </div>
              </div>
            </Link>
          </Col>

          {/* ECE/EEE card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/eee" className="text-decoration-none">
              <div className="card h-100 ece-eee-card">
                <div className="card-body text-center">
                  <h5 className="card-title">ECE / EEE</h5>
                </div>
              </div>
            </Link>
          </Col>
          
          {/* MECH card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/mechanical" className="text-decoration-none">
              <div className="card h-100 mech-card">
                <div className="card-body text-center">
                  <h5 className="card-title">MECH</h5>
                </div>
              </div>
            </Link>
          </Col>
          
          {/* Civil card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/civil" className="text-decoration-none">
              <div className="card h-100 civil-card">
                <div className="card-body text-center">
                  <h5 className="card-title">Civil</h5>
                </div>
              </div>
            </Link>
          </Col>
          
          {/* Management card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/management" className="text-decoration-none">
              <div className="card h-100 management-card">
                <div className="card-body text-center">
                  <h5 className="card-title">Management</h5>
                </div>
              </div>
            </Link>
          </Col>

          {/* Free Coding Class card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/coding" className="text-decoration-none">
              <div className="card h-100 coding-card">
                <div className="card-body text-center">
                  <h5 className="card-title">Coding Class</h5>
                </div>
              </div>
            </Link>
          </Col>
        </Row>









        <h1 className="text-center mb-4 heading">Product & IT Services</h1>

        <Row className="justify-content-center">
          {/* CSE card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/CareerPage" className="text-decoration-none">
              <div className="card h-100 cse-card">
                <div className="card-body text-center">
                  <h5 className="card-title">CareerPage</h5>
                </div>
              </div>
            </Link>
          </Col>

          {/* ECE/EEE card */}
          <Col xs={6} sm={6} md={4} className="mb-4 my-3">
            <Link to="/IndustrySelection" className="text-decoration-none">
              <div className="card h-100 ece-eee-card">
                <div className="card-body text-center">
                  <h5 className="card-title">IT Services</h5>
                </div>
              </div>
            </Link>
          </Col>
          
          
          
          
          
          

          
        </Row>
      </Container>
    </div>
  );
}

export default Subjects;
