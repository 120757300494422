import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const MachineLearningEngineer = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Machine Learning Engineer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>3-5 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-MLE-192522</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Machine Learning Engineer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Design and implement machine learning models to solve complex business problems and improve decision-making processes.</li>
                    <li>Collaborate with data scientists and software engineers to develop scalable and efficient machine learning solutions.</li>
                    <li>Analyze large datasets to extract meaningful insights and develop predictive models.</li>
                    <li>Train, test, and validate machine learning algorithms, ensuring high accuracy and robustness.</li>
                    <li>Optimize models for performance, scalability, and deployment in production environments.</li>
                    <li>Stay updated on the latest developments in machine learning technologies and techniques.</li>
                    <li>Create and maintain comprehensive documentation for machine learning models and processes.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Proficiency in programming languages such as Python, R, or Java for building machine learning models.</li>
                    <li>Experience with machine learning libraries/frameworks (TensorFlow, PyTorch, Scikit-learn, etc.).</li>
                    <li>Strong understanding of statistical methods, data analysis, and machine learning algorithms (e.g., supervised/unsupervised learning, reinforcement learning).</li>
                    <li>Experience with big data technologies (Hadoop, Spark) and cloud platforms (AWS, GCP, Azure).</li>
                    <li>Familiarity with data preprocessing, feature engineering, and model evaluation techniques.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong problem-solving skills and ability to think analytically.</li>
                    <li>Good understanding of software development principles and the ability to integrate ML models into production.</li>
                    <li>Excellent collaboration and communication skills to work in cross-functional teams.</li>
                    <li>A passion for staying updated with advancements in AI and machine learning.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s or Master’s degree in Computer Science, Data Science, Mathematics, or a related field.</p>
                <p>Relevant certifications in AI or machine learning are a plus.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default MachineLearningEngineer;
