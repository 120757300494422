import React from 'react';
import Subjects from './Subjects';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SocialMedia from './SocialMedia';
import Navbar123 from './Navbar123';

function Services() {
  return (
    <div>
               <Navbar123/>


      <Subjects/>
      <SocialMedia/>
    </div>
  )
}

export default Services
