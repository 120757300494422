import React from 'react';
import './Home.css';
import Content from './Content';
import Provider from './Provider';
import Subjects from './Subjects';
import SocialMedia from './SocialMedia';
import Marquee from './Marquee/Marquee';
import DetailsPage from './DetailsPage';
import AnimatedCounters from './AnimatedCounters';
import StudentReviews from './StudentReviews/StudentReviews';
import Navbar123 from './Navbar123';



function Home() {
    return (
        <div>
           <Navbar123/>


            
            {/* Content sections */}
            <Content />

            <Marquee />
            <Provider />
            <Subjects />
            <AnimatedCounters/>
           <StudentReviews/>
            {/* <Dummy /> */}
            {/* <Collage /> */}
            {/* <Dummy /> */}
            <DetailsPage/>
            {/* <HerosofCompany/> */}
            
            {/* <Tieup /> */}
            {/* <Marquee1/> */}
            {/* <OfficeMap/> */}
            <SocialMedia />
        </div>
    );
}

export default Home;
