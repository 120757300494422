import React from 'react'
import './DELD.css'
import CVLSI from '../Curriculum/EEE/CVLSI';
import SocialMedia from '../Home/SocialMedia';
import Offers from '../CSE/Offers';
import EEEPrice from '../Curriculum/EEE/EEEPrice';
import DetailsPage from '../Home/DetailsPage';

function DELD() {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

    return (
        <div>
        <div className='DELD_BG1'>
          <h1 className='DELD_H1'>VLSI</h1>
          <h4 className='DELD_H6 container'>Very Large Scale Integration (VLSI) combines thousands to millions of transistors on a single chip, enabling compact and efficient electronic devices like microprocessors and memory chips.</h4>
          <h4><button className='Mgbtn btn-dark d-flex m-5' onClick={() => scrollToSection('section5')}>Continue to pay</button></h4>

        </div>

       <Offers/>
      <CVLSI/>
      <DetailsPage/>
      <section id="section5">
    <EEEPrice/>
</section>

<SocialMedia/>
      </div>
      
      )
}

export default DELD
