import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const DataAnalyst = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Data Analyst</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>2-4 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-DA-192516</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Data Analyst</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Analyze large datasets to extract actionable insights and support data-driven decision-making.</li>
                    <li>Develop and maintain reports, dashboards, and visualizations to communicate findings effectively.</li>
                    <li>Collaborate with cross-functional teams to understand business needs and identify analytical opportunities.</li>
                    <li>Conduct data quality assessments and ensure the integrity of data used for analysis.</li>
                    <li>Stay updated with industry trends, best practices, and emerging technologies in data analytics.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: SQL, Excel, Tableau, Python, R.</li>
                    <li>Experience with data modeling and statistical analysis.</li>
                    <li>Familiarity with data warehousing concepts and tools.</li>
                    <li>Knowledge of machine learning algorithms is a plus.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong analytical and critical thinking skills.</li>
                    <li>Excellent communication skills to present findings to stakeholders clearly.</li>
                    <li>Ability to work independently and collaboratively in a team environment.</li>
                    <li>Detail-oriented with a strong focus on accuracy.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Data Science, Computer Science, Mathematics, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default DataAnalyst;
