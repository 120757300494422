import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './CareerPage.css'; // Assuming you have custom CSS here
import { Link } from "react-router-dom";

// Job data
const jobVacancies = [
  {
    title: "General Manager",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The General Manager will oversee all business operations, manage resources, and ensure profitability. The role requires excellent leadership skills, decision-making, and business strategy execution.",
    requirements: [
      "Bachelor's degree in Business Administration or related field",
      "10+ years of management experience",
      "Strong leadership and organizational skills",
      "Proven ability to manage large teams and complex projects",
    ],
    fileName: "/CareerPage/GeneralManager",
  },
  {
    title: "Java Developer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Java Developer will design, implement, and maintain Java-based applications, ensuring efficiency, reliability, and scalability.",
    requirements: [
      "Bachelor's degree in Computer Science or related field",
      "3+ years of experience in Java development",
      "Strong knowledge of Java frameworks such as Spring and Hibernate",
      "Experience with relational databases and RESTful APIs",
    ],
    fileName: "/CareerPage/JavaDeveloper",
  },
  {
    title: "Python Developer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Python Developer will build, test, and deploy web applications and backend services using Python and related frameworks.",
    requirements: [
      "Bachelor's degree in Computer Science or related field",
      "2+ years of experience in Python development",
      "Proficiency with Django or Flask frameworks",
      "Experience with databases and version control systems like Git",
    ],
    fileName: "/CareerPage/PythonDeveloper",
  },
  {
    title: "Software Tester",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Software Tester will ensure the quality of applications by performing manual and automated testing across various platforms.",
    requirements: [
      "Bachelor's degree in Computer Science or related field",
      "2+ years of experience in software testing",
      "Experience with testing tools such as Selenium and JIRA",
      "Strong analytical and problem-solving skills",
    ],
    fileName: "/CareerPage/SoftwareTester",
  },
  {
    title: "DevOps Engineer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The DevOps Engineer will manage infrastructure, automate deployment pipelines, and ensure the smooth operation of production environments.",
    requirements: [
      "Bachelor's degree in Computer Science or relevant field",
      "3+ years of experience in DevOps",
      "Experience with cloud platforms (AWS, Azure, GCP)",
      "Strong knowledge of CI/CD pipelines, containers, and infrastructure automation",
    ],
    fileName: "/CareerPage/DevOpsEngineer",
  },
  {
    title: "UI/UX Designer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The UI/UX Designer will create intuitive and visually appealing designs for web and mobile applications, focusing on user experience and interaction.",
    requirements: [
      "Bachelor's degree in Design, Fine Arts, or related field",
      "2+ years of experience in UI/UX design",
      "Proficiency in design tools such as Adobe XD, Figma, and Sketch",
      "Strong understanding of user-centered design principles",
    ],
    fileName: "/CareerPage/UIUXDesigner",
  },
  {
    title: "Mobile App Developer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Mobile App Developer will design and develop mobile applications for Android and iOS platforms, ensuring optimal performance and user experience.",
    requirements: [
      "Bachelor's degree in Computer Science or related field",
      "3+ years of experience in mobile app development",
      "Proficiency in React Native, Flutter, or Swift",
      "Experience with app store deployments and third-party integrations",
    ],
    fileName: "/CareerPage/MobileAppDeveloper",
  },
  {
    title: "Data Analyst",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Data Analyst will analyze large datasets to extract actionable insights, supporting data-driven decision making across the company.",
    requirements: [
      "Bachelor's degree in Data Science, Statistics, or related field",
      "2+ years of experience in data analysis",
      "Proficiency in SQL, Python, and data visualization tools like Tableau",
      "Strong analytical and statistical skills",
    ],
    fileName: "/CareerPage/DataAnalyst",
  },
  {
    title: "Cloud Architect",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Cloud Architect will design and implement scalable cloud solutions, optimizing resources and ensuring high availability for critical applications.",
    requirements: [
      "Bachelor's degree in Computer Science or relevant field",
      "4+ years of experience in cloud architecture",
      "Expertise in AWS, Azure, or Google Cloud Platform",
      "Experience in designing and deploying secure and scalable cloud solutions",
    ],
    fileName: "/CareerPage/CloudArchitect",
  },
  {
    title: "Database Administrator",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Database Administrator will manage and maintain the organization's databases, ensuring data integrity and security.",
    requirements: [
      "Bachelor's degree in Computer Science or related field",
      "3+ years of experience as a Database Administrator",
      "Proficiency in SQL, MySQL, or PostgreSQL",
      "Experience with database optimization and backup processes",
    ],
    fileName: "/CareerPage/DatabaseAdministrator",
  },
  {
    title: "Network Engineer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Network Engineer will design, implement, and maintain the organization's network infrastructure, ensuring uptime and security.",
    requirements: [
      "Bachelor's degree in Computer Science or related field",
      "2+ years of experience in network engineering",
      "Experience with network protocols, routers, switches, and firewalls",
      "Strong understanding of network security practices",
    ],
    fileName: "/CareerPage/NetworkEngineer",
  },
  {
    title: "Full-Stack Developer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Full-Stack Developer will work on both frontend and backend development, building scalable web applications.",
    requirements: [
      "Bachelor's degree in Computer Science or related field",
      "3+ years of experience in full-stack development",
      "Proficiency in frontend technologies (HTML, CSS, JavaScript) and backend frameworks (Node.js, Django)",
      "Experience with databases and API integration",
    ],
    fileName: "/CareerPage/FullStackDeveloper",
  },
  {
    title: "Cybersecurity Analyst",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Cybersecurity Analyst will ensure the company's systems and data are protected from security threats and breaches.",
    requirements: [
      "Bachelor's degree in Computer Science or relevant field",
      "2+ years of experience in cybersecurity",
      "Familiarity with security tools and frameworks like IDS/IPS, firewalls, and vulnerability scanners",
      "Strong problem-solving skills and knowledge of threat mitigation strategies",
    ],
    fileName: "/CareerPage/CybersecurityAnalyst",
  },
  {
    title: "Machine Learning Engineer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Machine Learning Engineer will build and deploy machine learning models to support the company's data-driven initiatives.",
    requirements: [
      "Bachelor's degree in Computer Science, Data Science, or related field",
      "2+ years of experience in machine learning",
      "Experience with ML frameworks like TensorFlow, PyTorch, or Scikit-learn",
      "Strong mathematical and programming skills in Python",
    ],
    fileName: "/CareerPage/MachineLearningEngineer",
  },
  {
    title: "Product Manager",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Product Manager will oversee product development from concept to launch, ensuring alignment with business objectives and customer needs.",
    requirements: [
      "Bachelor's degree in Business, Engineering, or related field",
      "3+ years of experience in product management",
      "Strong understanding of Agile methodologies and product lifecycle",
      "Excellent communication and leadership skills",
    ],
    fileName: "/CareerPage/ProductManager",
  },
  {
    title: "Data Scientist",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Data Scientist will be responsible for analyzing large datasets to provide actionable insights for the company. The role requires strong statistical knowledge, programming, and the ability to translate data into business solutions.",
    requirements: [
      "Bachelor's or Master's degree in Data Science, Statistics, or related field",
      "Proficiency in Python, R, and SQL",
      "Experience with machine learning algorithms and data visualization tools",
      "Strong problem-solving and communication skills",
    ],
    fileName: "/CareerPage/DataScientist",
  },
  {
    title: "Web Developer",
    location: "Ananthapur, Hyderabad-Bangalore Highway ",
    description:
      "The Web Developer will build and maintain functional and visually appealing websites. The role includes working with both front-end and back-end technologies, ensuring optimal performance and user experience.",
    requirements: [
      "Bachelor's degree in Computer Science or a related field",
      "Experience in HTML, CSS, JavaScript, and modern frameworks like React or Angular",
      "Proficiency in back-end languages like Node.js, PHP, or Python",
      "Strong understanding of responsive design and cross-browser compatibility",
    ],
    fileName: "/CareerPage/WebDeveloper",
  },
  {
    title: "Application Analyst",
    location: "Anantapur, Hyderabad-Bangalore Highway ",
    description:
      "The Application Analyst will evaluate, develop, and manage software applications to ensure they meet the needs of the company. The role involves troubleshooting, user support, and continuous improvement of application systems.",
    requirements: [
      "Bachelor's degree in Information Technology or a related field",
      "2+ years of experience in application management or development",
      "Strong analytical and problem-solving skills",
      "Experience with software development lifecycle and troubleshooting methodologies",
    ],
    fileName: "/CareerPage/ApplicationAnalyst",
  },
];

// Career Page Component
const CareerPage = () => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter job vacancies based on search
  const filteredJobs = jobVacancies.filter((job) =>
    job.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container py-5">
      <div className="text-center mb-5">
        <h1 className="display-4 fw-bold">Join Ahiraa Learning</h1>
        <p className="lead">Explore exciting career opportunities with us.</p>
      </div>

      {/* Search Bar */}
      <div className="row mb-4">
        <div className="col-md-6 mx-auto">
          <input
            type="text"
            className="form-control"
            placeholder="Search job titles..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        {filteredJobs.length > 0 ? (
          filteredJobs.map((job, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="job-card h-100 shadow">
                <div className="job-card-body">
                  <h5 className="job-card-title">{job.title}</h5>
                  <p className="job-card-text">{job.description.substring(0, 100)}...</p>
                  <p className="text-muted">Location: {job.location}</p>
                  <Link to={job.fileName}>
                    <button className="btn btn-primary">
                      View Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No jobs found matching your search.</p>
        )}
      </div>
    </div>
  );
};

export default CareerPage;
