import React from "react";
import './DataScientist.css'; // Custom CSS for styling

const MobileAppDeveloper = () => {
    return (
        <div className="container mt-5 mb-5 bg-light">
            <h1 className="text-center mb-4">Mobile App Developer</h1>
            
            {/* Important Notice */}
            <div className="alert alert-danger">
                <p className="notice">
                    Important Notice: Offer letters and other important documents from Ahiraa Learning Pvt. Ltd. are accessible only on our official career portal. Please log in using your credentials. We no longer send emails with offer letters attached. To validate your offer letter, use the <a href="#">career portal</a>.
                </p>
            </div>
            
            {/* Job Details */}
            <div className="row">
            
                <div className="col-md-6">
                    <h4>Location</h4>
                    <p>Anantapur, Hyderabad-Bangalore Highway </p>
                </div>
                <div className="col-md-6">
                    <h4>Experience</h4>
                    <p>2-4 Years</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Job ID</h4>
                    <p>AL-MAD-192515</p>
                </div>
                <div className="col-md-6">
                    <h4>Job Title</h4>
                    <p>Mobile App Developer</p>
                </div>
                <div className="col-md-6">
                    <h4>last date of Application</h4>
                    <p>22nd Feb 2025</p>
                </div>
                
            </div>
            
            {/* Responsibilities */}
            <div className="mt-4">
                <h3>Responsibilities</h3>
                <ul>
                    <li>Design and develop high-quality mobile applications for Android and iOS platforms.</li>
                    <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                    <li>Ensure the performance, quality, and responsiveness of applications.</li>
                    <li>Troubleshoot and debug applications to optimize performance.</li>
                    <li>Stay updated with the latest mobile development trends, technologies, and best practices.</li>
                    <li>Participate in code reviews to maintain code quality and share knowledge within the team.</li>
                </ul>
            </div>

            {/* Technical Requirements */}
            <div className="mt-4">
                <h3>Technical Requirements</h3>
                <ul>
                    <li>Primary Skills: Android (Java/Kotlin), iOS (Swift/Objective-C), React Native or Flutter.</li>
                    <li>Experience with RESTful APIs and third-party libraries for mobile development.</li>
                    <li>Familiarity with version control systems like Git.</li>
                    <li>Understanding of UI/UX principles for mobile applications.</li>
                </ul>
            </div>

            {/* Additional Skills */}
            <div className="mt-4">
                <h3>Additional Skills</h3>
                <ul>
                    <li>Strong analytical and problem-solving skills.</li>
                    <li>Ability to work effectively in a fast-paced, collaborative environment.</li>
                    <li>Excellent communication skills to convey technical concepts clearly.</li>
                    <li>A passion for creating intuitive and user-friendly mobile experiences.</li>
                </ul>
            </div>

            {/* Educational Requirements */}
            <div className="mt-4">
                <h3>Educational Requirements</h3>
                <p>Bachelor’s degree in Computer Science, Engineering, or a related field.</p>
            </div>

            {/* Apply Button */}
            <div className="text-center mt-5">
                <a href="/apply" className="btnJOB btn-apply btn-lg">Apply Now</a>
            </div>
        </div>
    );
};

export default MobileAppDeveloper;
